"use client"

import dynamic from "next/dynamic"
import { useRef, useState, useEffect } from "react"
import cn from "classnames"
import { useSelector, useDispatch } from "react-redux"
import { useValidateMobile } from "hooks/validateMobile"
import { useScroll } from "utils/hooks"
import { getNotifications, updateNotificationsAsRead } from "api/users"
import { setCurrentUser } from "reducers/auth"
import classNames from "classnames"

import Container from "react-bootstrap/Container"
import Logo from "components/logo"
import NavbarMobile from "components/Navbar/MobileNavbar"
import DesktopNavbar from "components/Navbar/DesktopNavbar"
import AuthSection from "components/Navbar/DesktopNavbar/AuthSection"
import MobileNotificationsToggle from "./NavNotifications/MobileNotificationsToggle"
import UserNotifications from "../shared/Notifications/UserNotifications"
import AccessExpiredHeader from "components/shared/Headers/AccessExpiredHeader"
import Card from "components/card"

const ReppysHeader = dynamic(() =>
  import("components/shared/Headers/ReppysHeader")
)

const SearchModal = dynamic(() => import("components/companies/search"))

import Search from "icons/search.svg"

import styles from "./Navbar.module.scss"

const Navbar = ({
  navbarBackground,
  solidNavbarMobile,
  showReppys,
  reppysDismissed,
  setReppysDismissed,
}) => {
  const authState = useSelector(state => state.auth || {})
  const { authenticated, currentUser, loading } = authState

  const { days_until_next_rating, status } = currentUser || {}
  const accessExpired =
    days_until_next_rating === 0 && status !== "new_to_sales"

  const { isMobile } = useValidateMobile(1200)
  const { scrollY } = useScroll()
  const [menuToggle, setMenuToggle] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchMove, setTouchMove] = useState(0)

  const [showSearchModal, setShowSearchModal] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [searchABFlag, setSearchABFlag] = useState(null)

  const navMenu = useRef(null)
  const loadingRef = useRef(true)

  const dispatch = useDispatch()

  const openMenu = () => {
    setMenuToggle(true)
    document.documentElement.classList.add("nav-open")
    window.addEventListener("mousedown", handleClickOutside)
  }
  const closeMenu = () => {
    setMenuToggle(false)
    document.documentElement.classList.remove("nav-open")
    window.removeEventListener("mousedown", handleClickOutside)
  }

  const handleClickOutside = event => {
    if (navMenu.current && !navMenu.current.contains(event.target)) {
      closeMenu()
    }
  }

  const handleSearchOpen = () => {
    setShowSearchModal(true)
  }

  const handleSearchClose = () => {
    setShowSearchModal(false)
  }

  const handleTouchStart = event => {
    const firstTouch = event.touches[0]
    const xLocation = firstTouch.clientX
    setTouchStart(xLocation)
  }

  const handleTouchMove = event => {
    const slideLocation = event.touches[0]
    const xSlide = slideLocation.clientX
    setTouchMove(xSlide)
  }

  const handleTouchEnd = () => {
    if (touchMove > touchStart) {
      closeMenu()
    }
  }

  const clearNotifications = () => {
    updateNotificationsAsRead().catch(error => console.error(error))
    dispatch(setCurrentUser({ ...currentUser, unread_notifications_count: 0 }))
  }

  const handleNotifications = () => {
    clearNotifications()
    if (showNotifications) {
      setShowNotifications(false)
    } else {
      getNotifications({ per_page: 4 })
        .then(res => {
          setNotifications(res.data)
          loadingRef.current = false
        })
        .catch(error => console.error(error))
      setShowNotifications(true)
    }
  }

  const handleReppysClose = event => {
    if (event) {
      event.preventDefault()
    }
    localStorage.setItem("reppysDismissedBanner", true)
    setReppysDismissed(true)
  }

  useEffect(() => {
    return () => {
      document.documentElement.classList.remove("nav-open")
    }
  }, [])

  useEffect(() => {
    if (!menuToggle) document.documentElement.classList.remove("nav-open")
  }, [menuToggle])

  useEffect(() => {
    if (setReppysDismissed) {
      if (localStorage.reppysDismissedBanner) {
        setReppysDismissed(true)
      } else {
        setReppysDismissed(false)
      }
    }
  }, [setReppysDismissed])

  return (
    <>
      <nav
        className={cn("navbar", styles.navBar, {
          [styles.wrapper__backgroundImage]: navbarBackground,
          [styles.wrapper__backgroundImage__banners]:
            accessExpired || (reppysDismissed === false && showReppys),
          [styles.wrapper__backgroundSolid]:
            (!navbarBackground && scrollY > 30) ||
            (isMobile && navbarBackground && scrollY > 30) ||
            (solidNavbarMobile && isMobile),
          [styles.wrapper__backgroundImage__hide]: menuToggle,
          "pt-0": accessExpired,
          [styles.navBar__oneBanner]:
            accessExpired ||
            (showReppys && reppysDismissed === false && !accessExpired),
        })}
        id="site-nav"
      >
        {accessExpired && <AccessExpiredHeader />}
        {showReppys && reppysDismissed === false && !!!accessExpired && (
          <ReppysHeader handleClose={handleReppysClose} />
        )}
        <Container
          className={classNames(styles.wrapper, "gx-5, gx-xl-0")}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          fluid="xl"
        >
          {isMobile && (
            <div className={styles.mobileView}>
              <div className={styles.mobileView__logo}>
                <Logo handleClick={closeMenu} />
              </div>
              <div className={styles.mobileView__controls}>
                {authenticated && (
                  <MobileNotificationsToggle
                    showNotifications={showNotifications}
                    handleNotifications={handleNotifications}
                  />
                )}
                <div className={styles.searchMobile} onClick={handleSearchOpen}>
                  <Search height="25" width="25" />
                </div>
                <div className={styles.toggleButton} onClick={openMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          )}

          {!isMobile && (
            <div className={`${styles.menu}`}>
              <Logo handleClick={closeMenu} />
              <div
                className={`${styles.search} ${styles.searchDark}`}
                onClick={handleSearchOpen}
              >
                <Search height="20" width="22" />
                <span>Search Companies</span>
              </div>

              <DesktopNavbar />

              <AuthSection
                authenticated={authenticated}
                loading={loading}
                currentUser={currentUser}
              />
            </div>
          )}
        </Container>
      </nav>

      {isMobile && (
        <section ref={navMenu}>
          <NavbarMobile
            closeMenu={setMenuToggle}
            open={menuToggle}
            authenticated={authenticated}
          />
        </section>
      )}
      {showNotifications && (
        <div
          className="fade modal-backdrop show"
          onClick={() => setShowNotifications(false)}
        ></div>
      )}

      <div className={styles.overlap}>
        <div
          className={classNames({
            [styles.notifications]: true,
            [styles.open]: showNotifications,
          })}
        >
          <Card offcanvas={true}>
            <UserNotifications
              hideNotifications={() => setShowNotifications(false)}
              notifications={notifications}
              loading={loadingRef.current}
            />
          </Card>
        </div>
      </div>

      <SearchModal
        show={showSearchModal}
        handleClose={handleSearchClose}
        location="Navbar Search Results"
        flag={searchABFlag}
      />
    </>
  )
}

export default Navbar
