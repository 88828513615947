import { useState, useEffect } from "react"
import { getQuestions } from "api/questions"
import { trackAction } from "utils/analytics"
import confetti from "canvas-confetti"

export const useInsights = (pageNum = 1, params, routerReady) => {
  const [results, setResults] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)

  useEffect(() => {
    if (!!!pageNum || !routerReady) return
    setIsLoading(true)
    setError(false)

    const controller = new AbortController()
    const { signal } = controller

    pageNum === 1 && setResults([])

    getQuestions(pageNum, params, { signal })
      .then(response => {
        setResults(prev => {
          return [...prev, ...response.data.questions]
        })
        setHasNextPage(Boolean(response.data.meta.next_page))
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
        if (signal.aborted) return
        setError(true)
      })

    return () => controller.abort()
  }, [pageNum, params])

  return { isLoading, error, results, hasNextPage }
}

export const useCopyClipboard = () => {
  const [isCopied, setIsCopied] = useState(false)

  const copyTextToClipboard = async text => {
    if ("clipboard" in navigator)
      return await navigator.clipboard.writeText(text)

    return document.execCommand("copy", true, text)
  }

  const handleCopyClick = (text, time = 0, analyticsConf) => {
    copyTextToClipboard(text).then(() => {
      if (analyticsConf) {
        const { label, location, click_text, click_url, modal_name } =
          analyticsConf
        trackAction(label, {
          location,
          click_text,
          click_url,
          modal_name,
        })
      }
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false)
      }, time)
    })
  }

  return { handleCopyClick, isCopied }
}

export const useScroll = () => {
  const [scrollY, setScrollY] = useState(0)

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return { scrollY }
}

export const useConfetti = goNuts => {
  useEffect(() => {
    if (goNuts) {
      const canvas = document.createElement("canvas")
      canvas.style.position = "absolute"
      canvas.style.top = 0
      canvas.style.left = 0
      canvas.style.width = "100%"
      canvas.style.height = "100%"
      canvas.style.zIndex = 0

      const layoutHeader = document.getElementById("layout-header")
      layoutHeader.appendChild(canvas)

      const confettiInstance = confetti.create(canvas, { resize: true })

      const confettiSettings = {
        particleCount: 5,
        angle: 90,
        spread: 110,
        startVelocity: 20,
        decay: 0.9,
        gravity: 0.85,
        ticks: 2500,
        shapes: ["square"],
        colors: ["#8D4CE0", "#00B876", "#DFEFFF", "#0077CC"],
        zIndex: 1000,
        scalar: 1.25,
      }

      const interval = setInterval(() => {
        const breakpointConfettiConfig = {
          1800: [0, 0.05, 0.1, 0.2, 0.4, 0.6, 0.8, 0.9, 0.95, 1],
          1200: [0, 0.1, 0.25, 0.5, 0.75, 0.9, 1],
          0: [0, 0.5, 1],
        }

        const breakpoints = Object.keys(breakpointConfettiConfig)
        const widthArray =
          breakpointConfettiConfig[
            Math.max(
              ...breakpoints
                .filter(breakpoint => breakpoint <= window.innerWidth)
                .map(Number)
            ) || 0
          ]

        widthArray.forEach(width => {
          confettiInstance({
            ...confettiSettings,
            origin: { x: width, y: 0 },
          })
        })
      }, 1000)

      return () => {
        clearInterval(interval)
        layoutHeader.removeChild(canvas)
      }
    }
  }, [goNuts])
}
