/* eslint-disable sonarjs/no-duplicate-string */

import { cleanTrim } from "utils/string"

import profile from "./profile.png"
import briefcase from "./briefcase.png"
import speechBubble from "./speech_bubble.png"
import conversationBubbles from "./conversation_bubbles.png"
import dollars from "./dollars.png"

const getCompleteProfileNotification = (reminder = false) => ({
  getLink: () => `/user/candidate-profile`,
  getContent: () => {
    const boldText = reminder
      ? "Reminder: Want more interviews?"
      : "Want more interviews?"
    return (
      <div>
        <b>{boldText}</b> Add a candidate profile and we&apos;ll connect you
        with hiring companies!
      </div>
    )
  },
  image: profile,
  category: "User Profile",
})

const buildJobRecommendationLink = industries => {
  let query = ""

  industries.forEach((industry, i) => {
    if (i === industries.length - 1) {
      query += `industries.name=${industry.name}`
    } else {
      query += `industries.name=${industry.name}&`
    }
  })

  return query
}

export const notificationDictionary = {
  "Company Questions": {
    getContent: ({ company_name, asked_by_repvue }) => (
      <div>
        {`${
          asked_by_repvue ? "The team at RepVue" : "A RepVue user"
        } asked a new question about `}
        <b>{company_name}</b>
      </div>
    ),
    getLink: ({ company_slug, question_slug }) =>
      `/companies/${company_slug}/questions/${question_slug}`,
    category: "Company Updates",
  },
  "Complete Your Profile Reminder": getCompleteProfileNotification(true),
  "Complete Your Profile": getCompleteProfileNotification(),
  "Job Application Declined": {
    image: briefcase,
    getContent: ({ company_name }) => (
      <div>
        Thank you for your interest in the role from {company_name}.
        Unfortunately, the hiring team has reviewed your profile and is
        currently moving forward with other candidates.
      </div>
    ),
    getLink: () => `/user/applications`,
    category: "Applications",
  },
  "Rate Your Previous Company": {
    image: speechBubble,
    getContent: () => (
      <div>
        Add a rating for a previous company where you worked. Share your
        experience and help others make informed career decisions!
      </div>
    ),
    getLink: () => `/rating/step1?previous_company=true`,
    category: "User Profile",
  },
  "New Answer": {
    image: conversationBubbles,
    getContent: ({ company_name, answer_body, parent_answer_id }) => (
      <div>
        A RepVue user{" "}
        {parent_answer_id ? "replied to your answer" : "answered your question"}{" "}
        about {company_name}:{" "}
        <b>
          &quot;
          {cleanTrim(answer_body, 50)}&quot;
        </b>
      </div>
    ),
    getLink: ({ company_slug, question_slug }) =>
      `/companies/${company_slug}/questions/${question_slug}`,
    category: "Company Updates",
  },
  "Question Published": {
    getContent: ({ company_name, question_body }) => (
      <div>
        Your question about {company_name} has been published:{" "}
        <b>
          &quot;
          {cleanTrim(question_body, 50)}&quot;
        </b>
      </div>
    ),
    getLink: ({ company_slug, question_slug }) =>
      `/companies/${company_slug}/questions/${question_slug}`,
    category: "Company Updates",
  },
  "Rate Your Current Company": {
    image: speechBubble,
    getContent: () => (
      <div>
        Improve your job matches and personalize your experience by rating your
        current company on RepVue - it only takes a minute!
      </div>
    ),
    getLink: () => `/rating/step1`,
    category: "Rating Prompt",
  },
  "Company Published": {
    getContent: ({ company_name }) => (
      <div>
        The company you rated, <b>{company_name}</b> is now ranked on RepVue!
        Check out their RepVue score and category rankings now!
      </div>
    ),
    getLink: ({ company_slug }) => `/companies/${company_slug}`,
    category: "Company Updates",
  },
  "Salary Comparison": {
    image: dollars,
    getContent: ({ salary_role }) => (
      <div>
        Your OTE seems to be below the typical range for {salary_role}s. Explore
        salaries for more compensation and quota attainment insights.
      </div>
    ),
    getLink: ({ salary_slug }) => `/salaries/${salary_slug}`,
    category: "Salary Comparison Alert",
  },
  "Talent Outreach": {
    getContent: ({ company_name, job_role }) => (
      <div>
        <b>{company_name}</b> is looking for candidates with your background and
        experience for their new job opening! Apply today for this{" "}
        <b>{job_role}</b> opening!
      </div>
    ),
    getLink: ({ job_slug }) => `/sales-jobs/${job_slug}`,
    category: "Talent Outreach",
  },
  "Add Review": {
    getContent: ({ company_name }) => (
      <div>
        Our community depends on your insights to make informed career
        decisions! Tap here to add a review of your experience at{" "}
        <b>{company_name}</b> to help your fellow sales pros!
      </div>
    ),
    getLink: () => `/user/dashboard`,
    category: "Review Prompt",
  },
  "Recommended Jobs": {
    getContent: () => (
      <div>
        Looking for your next opportunity? Check out these jobs that match your
        preferences!
      </div>
    ),
    getLink: ({ role, industries }) =>
      role === "SDR/BDR" || role === "Account Executive"
        ? `/sales-jobs?role=${role}&${buildJobRecommendationLink(industries)}`
        : `/sales-jobs?role=${role}`,
    category: "Review Prompt",
  },
  "Company Partially Published": {
    getContent: ({ company_name }) => (
      <div>
        Thanks to your help, <b>{company_name}</b> is published! The company has
        met our requirements to be live on RepVue but needs more verified
        ratings to be ranked. Refer your colleagues to get it ranked!
      </div>
    ),
    getLink: ({ company_slug }) => `/companies/${company_slug}`,
    category: "Company Updates",
  },
  "Publish Profile Reminder": {
    image: profile,
    getContent: () => (
      <div>
        You created your candidate profile, but didn&apos;t publish it yet. Your
        profile can&apos;t be shared until you publish it!
      </div>
    ),
    getLink: () => `/user/candidate-profile`,
    category: "Candidate Profile",
  },
}
